import { Controller }  from '@hotwired/stimulus'
import SetFlashMessage from './flash_message'

class ApplicationController extends Controller {

  /** Set to false in subclasses to use original Binding#invokeWithEvent */
  monkeyPatchBinding = true

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find((controller) => controller.context.identifier === identifier)
  }

  getControllerForElement(element, identifier) {
    return this.application.getControllerForElementAndIdentifier(element, identifier)
  }

  get flashController() {
    return this.getControllerByIdentifier('flash')
  }

  closeFlashMessage() {
    if (!this.flashController) return
    this.flashController.close()
  }

  renderFlashMessage(xhr) {
    this.closeFlashMessage()
    SetFlashMessage(xhr.getResponseHeader('X-Flash'))
  }

  /**
   * Send an error to Sentry.
   *
   * @param {Error} error
   */
  captureError(error, extraContext = {}) {
    HF.captureError(error, {
      contexts: {
        stimulus: { identifier: this.identifier },
        ...this.sentryContext(error),
        ...extraContext
      }
    })
  }

  /**
   * Provide additional context to Sentry when reporting errors.
   * Override in subclasses.
   *
   * @param {Error} _error
   * @returns {Record<string, object>}
   */
  sentryContext(_error) {
    return {}
  }

}

export default ApplicationController
