/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import { Application } from '@hotwired/stimulus'
import { Turbo }       from '@hotwired/turbo-rails'
import { metaContent } from '../support/helpers'

const SENTRY_DSN      = metaContent('app:sentry_dsn')
const SENTRY_STIMULUS = metaContent('app:sentry_stimulus')

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

function elementToString(element) {
  return element.cloneNode(false).outerHTML
}

function getTurboFrameCrumbs(element, sofar = []) {
  const frame = element.parentElement.closest('turbo-frame')
  if (!frame) return sofar.join(' > ')
  return getTurboFrameCrumbs(frame, [frame.id, ...sofar])
}

// Monkey-patch Binding#invokeWithEvent to catch async errors
function monkeyPatchBinding(bindingProto) {
  bindingProto._handleError = function _handleError(error, { currentTarget }) {
    const { identifier, controller, element, index, action } = this
    const detail = { identifier, controller, element, index, currentTarget, action: action.toString() }
    this.context.handleError(error, `invoking action "${this.action}"`, detail)
  }

  const originalInvokeWithEvent = bindingProto.invokeWithEvent
  // eslint-disable-next-line consistent-return
  bindingProto.invokeWithEvent = function invokeWithEvent(event) {
    if (!this.controller.monkeyPatchBinding) return originalInvokeWithEvent.call(this, event)

    const { target, currentTarget } = event
    try {
      const { params } = this.action
      const actionEvent = Object.assign(event, { params })
      const result = this.method.call(this.controller, actionEvent)
      if (result instanceof Promise) {
        result.catch((error) => this._handleError(error, event))
      }
      this.context.logDebugActivity(this.methodName, { event, target, currentTarget, action: this.methodName })
    } catch (error) {
      this._handleError(error, event)
    }
  }
}

// Control monkey patching with the :sentry_stimulus Flipper flag
if (SENTRY_DSN && SENTRY_STIMULUS === 'enabled') {
  application.dispatcher.bindingConnected = function bindingConnected(binding) {
    monkeyPatchBinding(Object.getPrototypeOf(binding))
    delete application.dispatcher.bindingConnected

    Object.getPrototypeOf(application.dispatcher).bindingConnected.call(this, binding)
  }

  const originalHandleError = application.handleError
  application.handleError = function handleError(...args) {
    originalHandleError.call(this, ...args)

    const [error, message, detail = {}]                     = args
    const { controller, identifier, currentTarget, action } = detail

    let controllerContext = {}
    if (controller && typeof controller.sentryContext === 'function') {
      controllerContext = controller.sentryContext() || {}
    }

    const controllerElement = controller ? elementToString(controller.element) : null
    const actionElement     = currentTarget ? elementToString(currentTarget) : null
    const element           = currentTarget || controller.element
    const turboFrameCrumbs  = element ? getTurboFrameCrumbs(element) : null

    HF.captureError(error, {
      contexts: {
        stimulus: { message, identifier, action, controllerElement, actionElement, turboFrameCrumbs },
        ...controllerContext
      }
    })
  }
}

// Handle Turbo redirects
// Drafted From: https://github.com/hotwired/turbo-rails/pull/367
// USAGE: turbo_stream.action(:redirect, url)
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target)
}

// eslint-disable-next-line
export { application }
