export default (xFlash) => {
  if (!xFlash) return

  const message  = xFlash.split('|')
  const template = document.querySelector('#flashTemplate')
  const node     = document.importNode(template.content, true)
  // Re-enable this when using URLs
  // const svg = node.querySelector('.flash').dataset.flashSvg

  node.querySelector('.flash').classList.add(`flash--${message[1]}`)
  node.querySelector('.flash__icon .a-glyph').classList.remove('-placeholder')
  node.querySelector('.flash__icon .a-glyph').classList.add(`-${message[0]}`)
  node.querySelector('.flash__icon .a-glyph use').setAttribute('href', `#${message[0]}`)
  node.querySelector('.flash__text').textContent = message[2]

  document.body.prepend(node)
}
