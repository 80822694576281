import I18n from './i18n'

/**
 * @param {number} n
 * @returns {string}
 */
function padTime(n) {
  if (n < 10) return `0${n}`
  return n
}

/**
 * @param {Date} time
 * @returns {string}
 */
export function formatTime(time, full = false) {
  const now        = new Date()
  const timeString = `${time.getHours()}:${padTime(time.getMinutes())}`

  if (!full && time.toDateString() === now.toDateString()) {
    return timeString
  }
  return `${padTime(time.getDate())}/${padTime(time.getMonth() + 1)}/${time.getFullYear()} ${timeString}`
}

function formatSize(number, unit) {
  const unitString = I18n.t(`number.human.storage_units.units.${unit}`, { count: number })
  return `${number} ${unitString}`
}

// Adapted from https://stackoverflow.com/a/14919494
export function formatFileSize(bytes, dp = 2) {
  const thresh = 1024

  if (Math.abs(bytes) < thresh) {
    return formatSize(bytes, 'byte')
  }

  const units = ['kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    u += 1
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return formatSize(bytes.toFixed(dp), units[u])
}

export default { formatTime, formatFileSize }
