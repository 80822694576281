import morphdom from 'morphdom'
import interpolate from './interpolate'

function render({ template, values = {} }) {
  const fragment = template.content.cloneNode(true)
  interpolate(fragment, values)
  return fragment.children[0]
}

function fillSlots(element, slots) {
  const slotTargets = element.querySelectorAll('[data-slot]')
  for (const slotTarget of slotTargets) {
    const slotName = slotTarget.getAttribute('data-slot')
    const slotElements = slots[slotName] || []
    slotElements.forEach((slotElement) => slotTarget.append(slotElement))
  }
}

function renderWithSlots({ slots = {}, ...component }) {
  const fragment = render(component)
  fillSlots(fragment, slots)
  return fragment
}

function update(element, component) {
  morphdom(element, render(component), {
    onBeforeElChildrenUpdated(fromEl) {
      if (fromEl.dataset?.slot) return false
      return true
    }
  })
}

const Nitrous = { render, renderWithSlots, update }

export default Nitrous
