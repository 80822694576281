const getParamByName = (keyArg, urlArg) => {
  let key = keyArg
  let url = urlArg

  if (!url) url = window.location.href
  key = key.replace(/[[\]]/g, '\\$&')

  const regex = new RegExp(`[?&]${key}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results) return null

  if (!results[2]) return ''

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const autoReFocus = (focusField) => {
  const element = document.getElementById(focusField)
  if (!element) return

  const value = element.value

  element.value = ''
  element.value = value
  element.focus()
}

const changeGlyph = (element, glyph) => {
  // Re-enable this when using URLs
  // const glyphSheetURL = new URL(element.querySelector('use').getAttribute('href').split('#')[0])
  // glyphSheetURL.hash = glyph
  const glyphSheetURL = glyph

  element.querySelector('use').setAttribute('href', glyphSheetURL)
}

const HTMLtoDOM = (html) => {
  const tempHtml = document.createElement('div')
  tempHtml.innerHTML = html
  return tempHtml.firstChild
}

const replaceDOM = (element, html) => {
  const fragment = document.createRange().createContextualFragment(html)
  element.replaceWith(fragment)
}

const isFileInput = (element) => element.nodeName === 'INPUT' && element.type === 'file'

const metaContent = (name) => {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element?.content
}

const animationFrame = () => new Promise((resolve) => {
  requestAnimationFrame(resolve)
})

const timeout = (ms) => new Promise((resolve) => {
  setTimeout(resolve, ms)
})

const scrollTopMax = (element) => element.scrollHeight - element.clientHeight

const isScrolledBottom = (element) => element.scrollTop >= scrollTopMax(element)

const isScrolledTop = (element) => element.scrollTop <= 0

const splitFilenameName = (filename) => {
  if (/^\.?[^.]+$|\.$/.test(filename)) return [filename.replace(/\.$/, ''), '']
  const parts = filename.split('.')
  const ext = parts[parts.length - 1]
  return [
    filename.replace(new RegExp(`\\.${ext}$`), ''),
    ext
  ]
}

const readFileAsDataUrl = (file) => {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.addEventListener('load', () => {
      resolve(reader.result)
    })

    reader.addEventListener('error', () => {
      reject(reader.error)
    })

    reader.readAsDataURL(file)
  })
}

const isDisabled = (el) => {
  switch (el.nodeName) {
    case 'BUTTON':
    case 'INPUT':
    case 'SELECT':
    case 'TEXTAREA':
      return el.disabled
    default:
      return el.hasAttribute('aria-disabled')
  }
}

/**
 * Disables the given element appropriately according to its type.
 * Uses disabled attribute for buttons and form elements.
 * Uses aria-disabled otherwise.
 *
 * Temporarily moves href attribut to data-href (and back again when enabling) for A tags
 *
 * @param {HTMLElement} el
 * @param {boolean} value True to disable, False to enable
 */
const disableElement = (el, value = true) => {
  if (value === isDisabled(el)) return

  switch (el.nodeName) {
    case 'BUTTON':
    case 'INPUT':
    case 'SELECT':
    case 'TEXTAREA':
      el.disabled = value
      break
    case 'A':
      if (value) {
        el.dataset.href = el.href
        el.removeAttribute('href')
      } else if (el.dataset.href) {
        el.setAttribute('href', el.dataset.href)
        el.dataset.href = null
      }
    // eslint-disable-next-line no-fallthrough
    default:
      if (value) {
        el.setAttribute('aria-disabled', 'true')
      } else {
        el.removeAttribute('aria-disabled')
      }
      break
  }
}

const enableElement = (el, value = true) => {
  disableElement(el, !value)
}

const disableElements = (el, value = true) => {
  for (const child of el.querySelectorAll('input,textarea,button,a,select')) {
    disableElement(child, value)
  }
}

const enableElements = (el, value = true) => {
  disableElements(el, !value)
}

const clearInputErrors = (el) => {
  for (const errorMsg of el.querySelectorAll('.a-ds2-input__error')) {
    errorMsg.remove()
  }

  for (const errorInput of el.querySelectorAll('.-error')) {
    errorInput.classList.remove('-error')
  }
}

export {
  animationFrame,
  autoReFocus,
  changeGlyph,
  clearInputErrors,
  disableElement,
  disableElements,
  enableElement,
  enableElements,
  getParamByName,
  HTMLtoDOM,
  isFileInput,
  isScrolledBottom,
  isScrolledTop,
  metaContent,
  readFileAsDataUrl,
  replaceDOM,
  scrollTopMax,
  splitFilenameName,
  timeout
}
